<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ $t('admin.users') }}
      </template>
    </RMPageHeader>
    <div class="row">
      <b-table hover :items="usersData" :fields="usersFields" id="usersTable"
       :per-page="perPage"
       :current-page="currentPage">
        <template #cell(address)="data">
          <span v-html="data.value"></span>
        </template>
        <template #cell(post)="data">
          <span v-html="data.value"></span>
        </template>
      </b-table>
      <b-pagination
          v-model="currentPage"
          :total-rows="usersData.length"
          :per-page="perPage"
          aria-controls="usersTable"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import {adminService} from "@/_services/admin.service";
import {handleError} from "@/_helpers/api";
import router from "@/config/PageRoutes";
import {mapState} from "vuex";
import {isUserAdmin} from "@/_helpers";
import RMPageHeader from "@/components/common/RMPageHeader.vue";

export default {
  name: "Users",
  components: {RMPageHeader},
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      usersData: [],
    }
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    usersFields() {
      return [
        {
          key: "name",
          label: this.$t("admin.name"),
          sortable: true
        },
        {
          key: "email",
          label: this.$t("myProfile.email"),
          sortable: true
        },
        {
          key: "address",
          label: this.$t("admin.address"),
          formatter(value, key, item) {
            let street = item.street;
            if (street === null) {
              street = ""
            }

            return street;
          },
          sortable: true
        },
        {
          key: "post",
          label: this.$t("admin.post"),
          formatter(value, key, item) {
            let postCode = item.post_code;
            if (postCode === null) {
              postCode = ""
            }

            let postOffice = item.post_office;
            if (postOffice === null) {
              postOffice = ""
            }

            return `${postCode} ${postOffice}`;
          },
          sortable: true
        },
        {
          key: "credit_amount",
          label: this.$t("buyCarrots.numberOfCarrots"),
          sortable: true
        },
        {
          key: "roles",
          label: this.$t("admin.roles"),
          formatter(value, key, item) {
            let roles = item.roles;
            if (roles === null) {
              return "";
            }

            return roles.join(", ");
          },
        },
      ]
    }
  },
  created() {
    if (!isUserAdmin()) {
      this.$router.push("/");
    }

    adminService.getUsersDetails().then((data) => {
      this.usersData = data.users;
    }).catch((error) => handleError(error, router, this._vm));
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
}
</script>

<style scoped>

</style>